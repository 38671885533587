import {
    DashboardOutlined,
    IdcardOutlined,
    UsergroupAddOutlined,
    OrderedListOutlined,
    FileAddOutlined,
    FundViewOutlined
} from '@ant-design/icons';
import {ADMIN_PREFIX_PATH, PRODUCER_PREFIX_PATH} from 'configs/AppConfig'

const dashBoardNavTreeAdmin = [
    {
        key: 'producers-list',
        path: ``,
        title: 'Producatori',
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'producers-list',
                path: `${ADMIN_PREFIX_PATH}/producers`,
                title: 'Lista Producatori',
                icon: IdcardOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'producers-create',
                path: `${ADMIN_PREFIX_PATH}/producers/create`,
                title: 'Producator nou',
                icon: UsergroupAddOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    },
    {
        key: 'category-category',
        path: ``,
        title: 'Categorii',
        icon: OrderedListOutlined,
        breadcrum: false,
        submenu: [
            {
                key: 'categories-b2c',
                path: `${ADMIN_PREFIX_PATH}/categories-b2c`,
                title: 'Categorii B2C',
                icon: OrderedListOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'categories-b2b',
                path: `${ADMIN_PREFIX_PATH}/categories-b2b`,
                title: 'Categorii B2B',
                icon: OrderedListOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    },
    {
        key: 'reports-list',
        path: ``,
        title: 'Rapoarte',
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'reports',
                path: `${ADMIN_PREFIX_PATH}/reports`,
                title: 'Rapoarte',
                icon: FundViewOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'six-month-reports',
                path: `${ADMIN_PREFIX_PATH}/six-month-reports`,
                title: 'Rapoarte 6 Luni',
                icon: FundViewOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    }
]

const navigationConfigAdmin = [
    ...dashBoardNavTreeAdmin
]

const dashBoardNavTreeProducer = [
    {
        key: 'home',
        path: `${PRODUCER_PREFIX_PATH}/home`,
        title: 'Dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
    },
    {
        key: 'B2C',
        type: 1,
        path: ``,
        title: 'B2C',
        breadcrumb: false,
        submenu: [
            {
                key: 'B2C-new-report',
                path: `${PRODUCER_PREFIX_PATH}/b2c/new-report`,
                title: 'Raport Nou B2C',
                icon: FileAddOutlined,
                breadcrumb: false,
                submenu: [

                ]
            },
            {
                key: 'B2C-reports',
                path: `${PRODUCER_PREFIX_PATH}/b2c/reports`,
                title: 'Rapoarte B2C',
                icon: FundViewOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    },
    {
        key: 'B2B',
        type: 2,
        path: ``,
        title: 'B2B',
        breadcrumb: false,
        submenu: [
            {
                key: 'B2B-new-report',
                path: `${PRODUCER_PREFIX_PATH}/b2b/new-report`,
                title: 'Raport Nou B2B',
                icon: FileAddOutlined,
                breadcrumb: false,
                submenu: [

                ]
            },
            {
                key: 'B2B-reports',
                path: `${PRODUCER_PREFIX_PATH}/b2B/reports`,
                title: 'Rapoarte B2B',
                icon: FundViewOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    }
]

const navigationConfigProducer = [
    ...dashBoardNavTreeProducer
]

export {navigationConfigAdmin, navigationConfigProducer};
